<template lang="pug">
include ../../../configs/template
div.row
  +select('body.delivery', 'listDelivery', 'delivery', 'nameLang')(item-value="delivery_service").col-12
  +select('body.morrichservice_office', 'listWerhouse', 'department', 'nameLang')(v-if="body.delivery === 'morrichservice'").col-12
  div.col-12
    v-btn(color="success" @click="saveDelivery" :loading="isLoading") {{$t('save')}}
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { SUCCESS_CODE } from '@/configs/constants'

export default {
  data () {
    return {
      body: {
        delivery: null,
        morrichservice_office: null
      },
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      listDelivery: state => state.delivery.typeDelivery.filter(el => !el.is_disable),
      listWerhouse: state => state.delivery.listWearHouseMorrichservice,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  methods: {
    ...mapActions(['updateRecordBookStatement']),
    async saveDelivery () {
      let data = {
        ...this.$route.params,
        body: this.body
      }
      const response = await this.updateRecordBookStatement(data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.$notification.success('notify.success.deliveryChanged')
      }
    }
  }
}
</script>
